import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
    const formRef = useRef();
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        message: ''
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then(() => {
                setLoading(false);
                setIsFormSubmitted(true);
                setFormData({
                    user_name: '',
                    user_email: '',
                    message: ''
                });
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error sending email:', error);
                alert('Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później.');
            });
    }

    return (
        <>
            <h2 className="head-text">Take a coffee & chat with me</h2>

            <div className="app__footer-cards">
                <div className="app__footer-card">
                    <img src={images.email} alt="email"/>
                    <a href="mailto:rafal_wilczewski@wp.pl" className="p-text">rafal_wilczewski@wp.pl</a>
                </div>
                <div className="app__footer-card">
                    <img src={images.mobile} alt="mobile"/>
                    <a href="tel: +48 789650326" className="p-text">tel: +48 789650326</a>
                </div>
            </div>

            {!isFormSubmitted ? (
                <form ref={formRef} className="app__footer-form app__flex" onSubmit={handleSubmit}>
                    <div className="app__flex">
                        <input
                            className="p-text"
                            type="text"
                            placeholder="Your Name"
                            name="user_name"
                            value={formData.user_name}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                    <div className="app__flex">
                        <input
                            className="p-text"
                            type="email"
                            placeholder="Your Email"
                            name="user_email"
                            value={formData.user_email}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                    <div>
                        <textarea
                            className="p-text"
                            placeholder="Your Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                    <button type="submit" className="p-text">
                        {loading ? 'Sending' : 'Send Message'}
                    </button>
                </form>
            ) : (
                <div>
                    <h3 className="head-text">Thank you for getting in touch!</h3>
                </div>
            )}
        </>
    );
};

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__whitebg'
);